import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ViewAllUsers from '../components/AllUsers'
import AllPurchases from '../components/AllPurchases'
import AllPurchasesTa from '../components/AllPurchasesTa'
import UserDetails from '../components/UserDetails'
import Courses from '../components/Courses'
import CourseWisePurchase from '../components/CourseWisePurchase'
import CourseWisePurchaseTa from '../components/CourseWisePurchaseTa'
import EditUser from '../components/EditUser'
import EditCourse from '../components/EditCourse'
import EditCourseDetails from '../components/EditCourseDetails'
import FranchiseDetail from '../components/FranchiseDetail'
import SearchResult from '../components/SearchResult'
import LoginCard from '../components/LoginCard'
import SignUp from '../components/SignUp'
import ChangePassword from '../components/ChangePassword'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'LoginCard',
    component: LoginCard
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword
  },
{
  path : '/AllPurchases',
  name : 'AllPurchases',
  component : AllPurchases
},
{
  path : '/AllPurchasesTa',
  name : 'AllPurchasesTa',
  component : AllPurchasesTa
},
{
  path : '/viewAllUsers',
  name : "viewAllUsers",
  component : ViewAllUsers
},

{
  path : '/Courses',
  name : "Courses",
  component : Courses
},
{
  path : '/user-details/:id',
  name : "userDetails",
  component : UserDetails
},
{
  path : '/search-result/:phone',
  name : "SearchResult",
  component : SearchResult
},
{
  path : '/CourseWisePurchase/:code',
  name : "CourseWisePurchase",
  component : CourseWisePurchase
},
{
  path : '/CourseWisePurchaseTa/:id',
  name : "CourseWisePurchaseTa",
  component : CourseWisePurchaseTa
},

{
  path : '/EditUser/:id',
  name : "EditUser",
  component : EditUser
},

{
  path : '/EditCourse/:id',
  name : "EditCourse",
  component : EditCourse
},

{
  path : '/EditCourseDetails/:id',
  name : "EditCourseDetails",
  component : EditCourseDetails
},
{
  path : '/FranchiseDetail/:id/:user',
  name : "FranchiseDetail",
  component : FranchiseDetail
},

]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
