<template >
  <v-layout row wrap>
    <v-flex xs12 md12>
  <v-app class="custom-style pl-10 pr-10">
    <!-- <home/> -->
    <v-main v-if= "LOGIN">
      <div>
       <v-app-bar app color="green" rounded >
        
        <v-app-bar-nav-icon dark small @click="drawer = !drawer">
         </v-app-bar-nav-icon>
          <v-avatar
              size="80"
               >
               <img :src="logo">
              </v-avatar>
              <div> <b>{{ADMIN.username}}</b> </div>
              <v-spacer></v-spacer>
              <div style="max-width:120px;font-weight:bold;padding-left:0;">
              <v-select
              background-color="green"
              v-model="districtValue"
              class="mt-7"
              label="DISTRICT"
             :items="districts"
              item-color="green"
              outlined
              @input="gotoDistrict" 
              dark
              dense
              >
              </v-select>
            </div>
          <v-navigation-drawer 
              v-model="drawer"
              height = "auto"
              width="auto"
              absolute
              temporary
          >
       <v-list-item-group 
          active-class="green--text"
        >
          <v-list-item @click="gotoHome">
            <v-list-item-content>
              <v-row justify="space-around">
                <v-avatar color="green">
                  <v-icon dark>
                    mdi-account-circle
                  </v-icon>
                </v-avatar>
                </v-row>
              <v-list-item-title class="text-h6 green--text text-center" > 
                  PRANA INSIGHT
              </v-list-item-title>
              <v-list-item-subtitle class="text-center">
                District Wise Users 
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          
          <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          @click="gotoPage(item.title)"
        >
          <v-list-item-icon>
            <v-icon >{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> 
      </v-list-item-group>
    </v-navigation-drawer>
       </v-app-bar>
        <router-view></router-view> 
      </div>
   
    </v-main>
    <v-main v-else>
   <div v-if="!SIGNUP" >
     
     <LoginCard/>
    </div>  
     <div v-else> 
      <sign-up/>
     </div> 
    </v-main>
  </v-app>
</v-flex>
</v-layout> 
</template>

<script>

import LoginCard from './components/LoginCard';
import { mapGetters } from 'vuex';
import SignUp from './components/SignUp.vue';

export default {
  name: 'App',
  data(){
    return{
      logo: require('./assets/logo.png'),
      drawer:false,
      items: [
          { title: 'HOME', icon: 'mdi-home' },
          { title: 'ALL USERS', icon: 'mdi-account' },
          { title: 'ACADEMIC COURSES', icon: 'mdi-cart' },
          { title: 'TALENT ACADEMY', icon: 'mdi-cart-outline' },
          { title: 'CHANGE PASSWORD', icon: 'mdi-shield-lock-outline' },
          { title: 'LOGOUT', icon: 'mdi-logout' },
                ],
        districtValue:"",
        districts: [
                     'Alappuzha',
                     'Ernakulam',
                     'Idukki',
                     'Kannur',
                    'Kasaragod',
                     'Kollam',
                     'kottayam',
                     'Kozhikode',
                     'Malappuram',
                     'Palakkad',
                     'Pathanamthitta',
                     'Thiruvananthapuram',
                     'Thrissur',
                     'Wayanad',
                     'Others'
    ],
        right: null,
        p:true
      }
    
  },
  computed:{
    ...mapGetters(['LOGIN','SIGNUP','ADMIN'])
  },
  methods : {
        gotoPage(page){
          if(page == "HOME"){
            this.$router.push({name:'Home'})
          }
           if(page == "ALL USERS"){
            this.$router.push({name:'viewAllUsers'})
          }
          else if(page == "ACADEMIC COURSES"){
            this.$router.push({name:'AllPurchases'})
          }
          else if(page == "TALENT ACADEMY"){
            this.$router.push({name:'AllPurchasesTa'})
          }
          else if(page == "CHANGE PASSWORD"){
            this.$router.push({name:'ChangePassword'})
          }
          else if(page == "LOGOUT"){
            this.drawer=false
          this.$store.dispatch('SET_LOGIN',false)
          this.$router.push('/login')
          
         }
        },
        gotoHome(){
          this.$router.push('/')
        },
        gotoDistrict(){
          let district = this.districtValue;
          let payload = {username:this.ADMIN.username,district:district}
          this.$store.dispatch('SET_ADMIN',payload)
          this.$router.go('')
        
        }
        
  },
  components: {
        LoginCard,SignUp
  }
};
</script>

<style>
.custom-style{
    font-family: 'Montserrat', sans-serif;
    /* background-color: #eceff1 !important; */
 

}
.row{
  margin: 0;
}
</style>
