<template>
    <v-layout row wrap class="mt-7">
            <v-flex xs12>
                <p class="white pa-3"><b>TOTAL PURCHASE - {{purchases.length}}</b></p>
            </v-flex>
             <v-flex xs12 >
                <v-simple-table class="">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                            <th class="text-left green--text">
                                NAME
                            </th>
                            <th class="text-left green--text">
                                PHONE
                            </th>
                            <th class="text-left green--text">
                                DISTRICT
                            </th>
                            
                            <th class="text-left green--text">
                                MODE
                            </th>
                            <th class="text-left green--text">
                                ACTION
                            </th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr
                                v-for="(item,index) in purchases" 
                                :key="item._id"
                               
                            >
                            <td>{{ index+1 }}</td>
                        
                            <td><b>{{ item.user.name }}</b></td>
                            <td>{{ item.user.phone }}</td>
                            <td>{{ item.user.district }}</td>
                            <!-- <td>{{ item.course }}</td> -->
                            <td v-if="item.franchise">Franchise</td>
                             <td v-else>Other</td>
                            
                            <!-- <td>{{ item.user.locality }}</td> -->
                            <td v-if="item.franchise">
                        
                                 <v-icon class="green--text pr-3" @click="franchiseDetails(item.franchise._id, item.user._id)">search</v-icon>
                                
                            </td>
                            <td v-else>
                                  <v-icon class="green--text pr-3" @click="details(item.user._id)">search</v-icon>
                            </td>

                            </tr>
                        </tbody>
                        
                        </template>
                </v-simple-table>
                
        </v-flex>
        <!-- <v-flex v-else md12 class="text-center mt-6">
                <span class="white px-3">No purchased users in this course</span>
        </v-flex> -->
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'

export default {
    data(){
        return {
            userDetails : {},
            purchases : [],
            sorted_list : []
        }
    },
      methods : {
        franchiseDetails(id,user){
            this.$router.push({name : 'FranchiseDetail',params : {id : id, user : user}})
        },
        details(id){
            this.$router.push({name : 'userDetails',params : {id : id}})
        },
      },
    async mounted(){
     
        {
            //http://3.7.194.167:5006/api/v1/purchase-by-course-request?code=LAB-M
            let url = BASE_URL + "/purchase-by-course-request-ta?id="+ this.$route.params.id
            let {data} = await Axios.get(url)
            this.purchases = data
          
            // this.purchases.sort((a,b)=> (a.franchise < b.franchise ? 1 : -1))
        }        
    }
}
</script>