import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueToastr from 'vue-toastr'
import CKEditor from 'ckeditor4-vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted,
  {duration:3000,
    theme:'outline',
    position:'top-center'
  })
Vue.use(VueToastr);
Vue.use( CKEditor );

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
