import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"; 

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    login : false,
    admin : {},
    signup:'',
    changePassword:""
  },
  getters : {
    LOGIN: state => {
      return state.login
    },  
    ADMIN: state => {
      return state.admin
    },  
    SIGNUP: state => {
      return state.signup
    }, 
    CHANGEPASSWORD: state => {
      return state.changePassword
    },
  },

  mutations: {
    SET_LOGIN: (state, payload) => {
      state.login = payload
     },
     SET_ADMIN: (state, payload) => {
        state.admin = payload
     },
     SET_SIGNUP: (state, payload) => {
      state.signup = payload
   },
   SET_CHANGEPASSWORD: (state, payload) => {
    state.changePassword = payload
 },
   },

  actions: {
    SET_LOGIN : async (context, payload) => {
      context.commit("SET_LOGIN",payload)
      
    },
    SET_ADMIN : async (context, payload) => {
      context.commit("SET_ADMIN",payload)
    },
    SET_SIGNUP : async (context, payload) => {
      context.commit("SET_SIGNUP",payload)
    },
    SET_CHANGEPASSWORD : async (context, payload) => {
      context.commit("SET_CHANGEPASSWORD",payload)
    }
    
  },
  modules: {
    
  }
})
