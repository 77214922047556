<template>
    <v-layout row wrap>
        <v-flex xs12 md8>
            <div class="white mt-8">
                    <h3 class="green--text  py-1" style="text-align:center">
                        USERS DETAILS
                    </h3>
            </div>
                <v-simple-table class="mt-5" >
                        <template v-slot:default >
                        <tbody>
                            <tr>
                                <td ><b>NAME</b></td>
                                <td>{{userDetails.name}}</td>
                            </tr>
                            <tr>
                                <td><b>PHONE</b></td>
                                <td>{{userDetails.phone}}</td>
                            </tr>
                            <tr>
                                <td><b>DISTRICT</b></td>
                                <td>{{userDetails.district}}</td>
                            </tr>
                            <tr>
                                <td><b>LOCALITY</b></td>
                                <td>{{userDetails.locality}}</td>
                            </tr>
                            <tr>
                                <td><b>PINCODE</b></td>
                                <td>{{userDetails.pincode}}</td>
                            </tr>
                            <tr>
                                <td><b>POST OFFICE</b></td>
                                <td>{{userDetails.po}}</td>
                            </tr>
                            <tr>
                                <td><b>VERIFIED </b></td>
                                <td class="indigo--text">{{userDetails.verified}}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
        </v-flex>
      
          <v-flex xs12 pl-3 md4>
              <div class="white mt-10">
                    <h3 class="green--text text-center py-1">
                        PURCHASE HISTORY
                    </h3>
              </div>
                <p class="caption pa-2 white mt-3"><b>ACADEMIC COURSES</b></p>
                <v-simple-table  class="mt-2 ">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                         
                            <th class="text-left green--text">
                                COURSE
                            </th>
                            </tr>
                        </thead>
                        <tbody v-if="purchases.length >0">
                            <tr
                                v-for="(item,index) in purchases"
                                :key="item._id"
                            >
                            <td>{{ index+1 }}</td>
                            <td>{{ item.course }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                        <tr class="text-center" style="color:crimson"> No purchases yet!!
                              </tr> 
                            </tbody>
                           
                        </template>
                </v-simple-table>
                <p class="caption pa-2 white mt-3"><b>TALENT ACADEMY</b></p>
                <v-simple-table  class="mt-2 ">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left green--text">
                                #
                            </th>
                         
                            <th class="text-left green--text">
                                COURSE
                            </th>
                            </tr>
                        </thead>
                       
                        <tbody v-if="purchasesTa.length >0">
                            <tr
                                v-for="(item,index) in purchasesTa"
                                :key="item._id"
                            >
                            <td>{{ index+1 }}</td>
                            <td>{{ item.course.course }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                             <tr class="text-center" style="color:crimson">  No purchases yet!!
                             </tr>
                        </tbody>  
                    </template>
                </v-simple-table>
        </v-flex> 
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'

export default {
    data(){
        return {
            userDetails : [],
            purchases : [],
            purchasesTa : [],
            courses : [],
            coursesTa : [],
            searchCourse : "",
            searchCourseTa : "",
            type : "",
          
        }
    },
    methods : {
        async unlockCourse(item){
            let url = BASE_URL + "/unlock-course"
            let payload = {
                code : item.code,
                user : this.userDetails._id,
                deviceId : this.userDetails.device_id
            }
            let {data} =  await Axios.post(url,payload)
            
        },
        async unlockCourseTa(item){
            let url = BASE_URL + "/unlock-course-ta"
            let payload = {
                course : item._id,
                 user : this.userDetails._id,
                deviceId : this.userDetails.device_id
            }
            let {data} =  await Axios.post(url,payload)
        },
        academic(){
            this.type = 'ACADEMIC'
        },
        ta(){
            this.type = "TA"
        }
    },
    async mounted(){
        {
            let url = BASE_URL + "/auth/users/details?id="+this.$route.params.id
            let {data} = await Axios.get(url)
            this.userDetails = data
        }
        {
            let url = BASE_URL + "/user-purchase?id="+this.$route.params.id
            let {data} = await Axios.get(url)
            this.purchases = data
            
        } 
        {
            let url = BASE_URL + "/user-purchase-ta?id="+this.$route.params.id
            let {data} = await Axios.get(url)
           this.purchasesTa = data
        } 
        {
            
            let url = BASE_URL + "/course-details/all"
            let {data} = await Axios.get(url)
            this.courses = data
            
        }
        {
            let url = BASE_URL + "/talent-academy/course"
            let {data} = await Axios.get(url)
            this.coursesTa = data
            
        }

    },
    computed : {
        filteredCourses(){
            if(this.searchCourse == ""){
                return []
            }
            else{
                return this.courses.filter(data => data.name.toLowerCase().includes(this.searchCourse.toLowerCase()))
            }
            
        },
         filteredCoursesTa(){
            if(this.searchCourseTa == ""){
                return []
            }
            else{
                return this.coursesTa.filter(data => data.course.toLowerCase().includes(this.searchCourseTa.toLowerCase()))
            }
            
        },
        
    
        }
    }

</script>