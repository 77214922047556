<template class="">
    <v-layout row wrap style="height:100%">
         <v-flex class="xs12 md6 offset-md-3">
             <div class="flex pa-10 white elevatin-2 xs12">
              <div class="vertical-spacing"></div>
               <div class="text-center">
                 <img src="../assets/logo.png" width="300px" />
                  <h2 class="mt-2 green--text text--darken-4">LOGIN</h2>
               </div>
               <v-container>
                 <v-row dense >
                    <v-col  cols="12" md="12" color="green">
                        <v-text-field color="green" outlined autofocus v-model="email" class="mt-5" label="email" type="email" prepend-inner-icon="mdi-email" clearable> 
                        </v-text-field>
                     </v-col>
                 <v-col cols="12" md="12" >
                     <v-text-field color="green" outlined v-model="password" class="mt-5" label="Password" type="password" @keydown.enter="login" prepend-inner-icon="mdi-lock-open" clearable> 
                     </v-text-field>
                  </v-col>
                    <v-col  cols="12" md="12" color="green">
                    </v-col>
                    <v-col cols="12" md="12" align="center">
                    <v-btn xs12 @click="login" rounded large class="green white--text px-12 mt-10 mx-2">Login</v-btn>
                    <v-btn xs12 @click="signUp" rounded large outlined class="green--text mt-10 pl-10 pr-10 mx-2">sign up</v-btn>    
                    </v-col>
                    </v-row>
               </v-container>
             </div>
         </v-flex>
     </v-layout>
 </template>
 <script>
  import Axios from 'axios'
  import { BASE_URL } from '../config'

 export default {
     data(){
         return{
             email:"",
             password : "",
             items: [
                     'Alappuzha',
                     'Ernakulam',
                     'Idukki',
                     'Kannur',
                     'Kasaragod',
                     'Kollam',
                     'kottayam',
                     'Kozhikode',
                     'Malappuram',
                     'Palakkad',
                     'Pathanamthitta',
                     'Thiruvananthapuram',
                     'Thrissur',
                     'Wayanad',
                     'Others'
      ],
      select:null
         }
     },
     methods : {
        async login(e){
            //email validation method
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
            e.preventDefault()
                let payload = 
                            {
                            email : this.email,
                            password : this.password,
                            }
                // let url =  "http://localhost:5000/api/v1/auth/admin-district/login"
                let url = BASE_URL + "auth/admin-district/login"
                let {data} = await Axios.post(url,payload)
                            .then((response) => {   
                                this.$toasted.error(response.data.msg )
                                let payload = {district:response.data.user.district,
                                                username:response.data.user.username}
                                this.$store.dispatch("SET_LOGIN",true)
                                this.$store.dispatch("SET_ADMIN",payload)
                                this.$store.dispatch("SET_SIGNUP",false)
                                this.$store.dispatch("SET_CHANGEPASSWORD",this.email)
                                this.$router.push("/")    
                            })    
                            .catch((err) => {    
                                console.log(err)    
                            })       
            }
            else{
                this.$toasted.error('Please enter a valid email address')
        }
    },
            signUp(){
                this.$store.dispatch("SET_SIGNUP",true)
                this.$router.push('/signup') 
            }
    }
}
 </script>
