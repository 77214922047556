<template>
    <v-layout row wrap>
        <v-flex xs12>
            <div class="white mt-8">
                    <h3 class="green--text  py-1" style="text-align:center">
                        ALL USERS <br>{{ADMIN.district}}
                    </h3>
              </div>
              <div class="text-center mt-2">
                            <v-pagination
                            v-model="page"
                            :length="pageLength"
                            :total-visible="10"
                            @input="gotoPage(page)"
                            color="green"
                            ></v-pagination>
                    </div>
        </v-flex>
        <v-flex xs12 class="mt-2 white pa-5">
            <v-layout row wrap>
                <v-flex xs12 md10>
                    <v-text-field color="green" label="Phone" single-line  outlined v-model="phone_number" @keydown.enter="search"></v-text-field>
                </v-flex>
                <v-flex xs12 md2>
                    <v-expand-transition > 
                         <v-btn v-show="phone_number != ''" class="green white--text" block @click="search" style="height:55px">search</v-btn>
                         </v-expand-transition>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex>
        </v-flex>
        <v-flex xs12 style="text-align:center" class="">
            <v-progress-circular
                indeterminate
                color="green"
                v-if="loading"
            >
            </v-progress-circular></v-flex>
            <v-flex xs12>   
        <v-layout row wrap>
                <v-flex xs12 class=" white pa-3 pb-1" >
                    <p class="green--text text-center mt-2 mr-6"><b>TOTAL USERS - {{ADMIN.district}} :  {{count}}
                    </b></p>
                </v-flex>
            </v-layout>
         </v-flex>
        <v-flex >
            <v-container class=" lists white" >
            <v-layout row wrap xs12
                v-for="(item,index) in users"
                :key="item._id"
                @click="viewUserDetails(item._id)"
                class="ma-4 "
            >
                        <v-flex xs2 md1 >
                        <span >{{(skip*100) + index+1 }}</span>
                        </v-flex>
                        <v-flex xs10 md11>
                        <span><b>{{ item.name }}</b> <br>
                            <span>
                                <v-icon color="green" small>mdi-cellphone
                                </v-icon>{{ item.phone }}
                            </span>
                        </span>
                        <v-divider></v-divider>
                    </v-flex> 
                     
            </v-layout> 
        </v-container> 
        <div class="text-center mt-2">
                            
                            <v-pagination
                            v-model="page"
                            :length="pageLength"
                            :total-visible="10"
                            @input="gotoPage(page)"
                            color="green"
                            >
                        </v-pagination>
                    </div> 
        </v-flex>
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            users : [],
            skip : 0,
            loading : false,
            count : "",
            phone_number : "",
            page:1,
            pageLength:0
        }
    },
    computed:{
        ...mapGetters(['ADMIN'])
    },
    async mounted(){
       
        {   
            this.loading = true
            let url =  BASE_URL + "/auth/users/districtwise?district=" + this.ADMIN.district + "&skip=" + this.page
            // let url =  "http://localhost:5000/api/v1/auth/users/districtwise?district=" + this.ADMIN + "&skip=" + this.page
            let {data} = await Axios.get(url)  
            this.users = data
            this.users.sort((a,b)=> (a.name < b.name ? -1 : 1))
            this.loading = false 
        }      
        {
            let url =  BASE_URL + "/auth/districtwise/count?district=" + this.ADMIN.district 
            // let url = "http://localhost:5000/api/v1/auth/districtwise/count?district=" + this.ADMIN
            let {data} = await Axios.get(url)
            this.count = data.count
            this.pageLength =  parseInt((data.count/100)) +1
        }  
    },
   
    methods : {
        search(){
            this.$router.push({name : "SearchResult",params : {phone : this.phone_number}})   
        },
        viewUserDetails(id){
            this.$router.push({name : 'userDetails',params : {id : id}})
        },
        // editUserDetails(id){
        //     this.$router.push({name : 'EditUser',params : {id : id}})
        // },
       async gotoPage(page){
        this.loading = true
        this.skip = page - 1
        let url = BASE_URL + "/auth/users/districtwise?district=" + this.ADMIN.district + "&skip=" + this.skip
        // let url =  "http://localhost:5000/api/v1/auth/users/districtwise?district=" + this.ADMIN + "&skip=" + this.skip
        let {data} = await Axios.get(url)  
        this.users = data
        this.loading = false
        }  
    }
}
</script>

<style>
 .lists :hover{
    background: rgb(219, 233, 219);
 }
</style>

