<template>
    <v-layout row wrap class="ma-4">
        <v-flex>
            <div class="white mt-2">
                    <h3 class="green--text text-center py-1">
                      TALENT ACADEMY PURCHASED USERS <br>
                      {{ADMIN.district}}
                    </h3>
              </div>
        <v-flex xs12 class="mt-2 white pa-5">
            <v-layout row wrap>
                <v-flex xs12 md10 class="pr-1">
                    <v-text-field color="green" label="Phone" single-line outlined v-model="phone_number" @keydown.enter="search"></v-text-field>
                </v-flex>
                <v-flex xs12 md2>
                    <v-expand-transition > 
                         <v-btn v-show="phone_number != ''" class="green white--text" block @click="search" style="height:55px">search</v-btn>
                         </v-expand-transition>
                </v-flex>
            </v-layout>
        </v-flex>
        </v-flex>
        <v-flex xs12>
            <v-flex xs12 class="mt-2 white pa-3 pb-1 text-center" >
                    <p class="green--text pt-2" ><b>TALENT ACADEMY PURCHASES :  {{count}}</b></p>
             </v-flex>
                <v-flex >
            <v-container class=" lists" >
            <v-layout row wrap xs12
                v-for="(item,index) in purchases"
                :key="item._id"
                @click="gotoDetails(item.user.phone)"
                class="ma-4 "
                >       
                 <v-flex xs2 md1 >
                    <span >{{index+1 }}</span>
                 </v-flex>
                        <v-flex xs10 md11 >
                        <span v-if="item.user != null"><b>{{ item.user.name }} </b> <br>
                            <span class="mt-1" v-if="item.user != null">
                                <v-icon color="green" small>mdi-cellphone
                                </v-icon>{{ item.user.phone }}
                            </span><br>
                            <p class="caption mt-1" v-if="item.user != null">
                                <v-icon color="green" small>mdi-book-open-page-variant
                                </v-icon> {{ item.course.course }} - <span><b> ₹{{ item.course.sp }}</b></span>
                            </p>
                        </span>
                        <v-divider></v-divider>
                    </v-flex>  
            </v-layout> 
        </v-container> 
        </v-flex>
        </v-flex>
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            userDetails : {},
            purchases : [],
            sorted_list : [],
            count:"",
            distCount:"",
            phone_number:"",
            items: [
                    { title: 'Alappuzha' },
                    { title: 'Ernakulam' },
                    { title: 'Idukki' },
                    { title: 'Kannur' },
                    { title: 'Kasaragod' },
                    { title: 'Kollam' },
                    { title: 'Kottayam' },
                    { title: 'Kozhikode' },
                    { title: 'Malappuram' },
                    { title: 'Palakkad' },
                    { title: 'Pathanamthitta' },
                    { title: 'Thiruvananthapuram' },
                    { title: 'Thrissur' },
                    { title: 'Wayanad' },
                    { title: 'Others'}

      ],
      
        }
    },
    
    computed:{
        ...mapGetters(['ADMIN'])
    },
    async mounted(){
     
        {
            let url =  BASE_URL + "/all-purchase-request-ta-district?district=" + this.ADMIN.district
            let {data} = await Axios.get(url)
            for(let i=0; i<data.length; i++){
                if(data[i].payment){
                    this.purchases.push(data[i])
                }
            }
            // this.purchases.sort((a,b)=> (a.user.name < b.user.name ? -1 : 1))
            // this.purchases = data
           
            this.count = this.purchases.length
        }        
    },
     methods:{
          search(){
            if(this.searchText != ""){
            this.$router.push({name : 'SearchResult', params :{phone : this.phone_number}})
            }
        },
        gotoDetails(phone){
            this.$router.push({name : 'SearchResult', params :{phone : phone}})
        },
    },
}
</script>