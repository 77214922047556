<template>
    <v-layout row wrap class="ma-4">
        <v-flex>
            <div class="white mt-2">
                    <h3 class="green--text text-center py-1">
                        ACADEMIC COURSES PURCHASED USERS <br>
                        {{ADMIN.district}}
                    </h3>
              </div>
        <v-flex xs12 class="mt-2 white pa-5">
            <v-layout row wrap>
                <v-flex xs12 md10 class="pr-1">
                    <v-text-field color="green" label="Phone" single-line outlined v-model="phone_number" @keydown.enter="search"></v-text-field>
                </v-flex>
                <v-flex xs12 md2>
                    <v-expand-transition > 
                         <v-btn v-show="phone_number != ''" class="green white--text" block @click="search" style="height:55px">search</v-btn>
                         </v-expand-transition>
                </v-flex>
            </v-layout>
        </v-flex>
        </v-flex>
        <v-flex xs12 style="text-align:center" >
            <v-progress-circular
                indeterminate
                color="green"
                v-if="loading"
            >
            </v-progress-circular></v-flex>
        <v-flex xs12>
            <v-flex xs12 class="mt-2 white pa-3 pb-1" >
                    <p class="green--text text-center pt-2" ><b>ACADEMIC COURSES PURCHASES : {{count}}</b></p>
                </v-flex>
                <v-flex >
            <v-container class=" lists">
            <v-layout row wrap xs12
                v-for="(item,index) in purchases"
                :key="item._id"
                @click="gotoDetails(item.user.phone)"
                class="ma-4 "
            >
                        <v-flex xs2 md1 >
                        <span >{{index+1 }}</span>
                        </v-flex>
                        <v-flex xs10 md11>
                        <span><b>{{ item.user.name }}</b> <br>
                            <span class="mt-1">
                                <v-icon color="green" small>mdi-cellphone
                                </v-icon>{{ item.user.phone }}
                            </span>
                            <p class="caption mt-1">
                                <v-icon color="green" small>mdi-book-open-page-variant
                                </v-icon>{{  item.course }} - <span><b>₹10000</b></span>
                            </p>
                        </span>
                        <v-divider></v-divider>
                    </v-flex>     
            </v-layout> 
        </v-container> 
        </v-flex>
        </v-flex>
    </v-layout>
</template>

<script>
import { BASE_URL } from '../config'
import Axios from 'axios'
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            userDetails : {},
            loading : false,
            purchases : [],
            sorted_list : [],
            count:"",
            phone_number:"",
            distCount:"",
        }
    },
    computed:{
        ...mapGetters(['ADMIN'])
    },
    async mounted(){
     
        {   
            this.loading = true
            let url = BASE_URL + "/all-purchase-request-district?district=" + this.ADMIN.district
            // let url =  "http://localhost:5000/api/v1/all-purchase-request-district?district=" + this.ADMIN 
            let {data} = await Axios.get(url)
            for(let i=0; i<data.length; i++){
                if(data[i].payment){
                    this.purchases.push(data[i])
                }
            }
            this.count = this.purchases.length
            this.loading = false
        }        
    },
     methods:{
          search(){
            if(this.searchText != ""){
            this.$router.push({name : 'SearchResult', params :{phone : this.phone_number}})
            }
            },
            gotoDetails(phone){
                this.$router.push({name : 'SearchResult', params :{phone : phone}})
            },
            editDetails(id){
                this.$router.push({name : 'EditUser',params : {id : id}})
            },
            async filterDistrict(district){
                    let url = BASE_URL + "/all-purchase-request"
                    let {data} = await Axios.get(url)
                    let filteredPurchases = data
                    filteredPurchases = filteredPurchases.filter((item) =>{
                    if(item.user.district != null){
                        return(item.user.district.toUpperCase() == district.toUpperCase())
                    }
                    
                    else if(district == "Others"){
                        return (item.user.district == null)
                    }
                })
                this.distCount = filteredPurchases.length
                return (this.purchases = filteredPurchases)
             }
          }
}
</script>